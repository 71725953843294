import React from 'react';

import '../styles/main.scss';

import { MetaData } from '../components/MetaData';
import { PrivacyNotice } from '../scenes/PrivacyNotice';
import { Footer } from '../scenes/Footer';

export default () => (
  <div id="root">
    <PrivacyNotice />
    <Footer />
    <MetaData />
  </div>
);
