import * as React from 'react';
import { Header } from '../Header';

import './styles.scss';

export const PrivacyNotice = () => (
  <div className="privacy-notice">
    <Header />

    <div className="privacy-notice__wrapper">
      <h2>Datenschutzerklärung</h2>
      <p>
        Diese Datenschutzerklärung klärt Sie über die Art, den Umfang
        und Zweck der Verarbeitung von personenbezogenen Daten
        (nachfolgend kurz „Daten“) im Rahmen der Erbr /ingung unserer
        Leistungen sowie innerhalb unseres Onlineangebotes und der mit
        ihm verbundenen Webseiten, Funktionen und Inhalte sowie
        externen Onlinepräsenzen, wie z.B. unser Social Media Profile
        auf (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im
        Hinblick auf die verwendeten Begrifflichkeiten, wie z.B.
        „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die
        Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).{' '}
        <br />
        <br />
      </p>
      <h3 id="dsg-general-controller">Verantwortlicher</h3>
      <p>
        <span className="tsmcontroller">
          Nikola Andelkovic
          <br />
          Obere Markstraße 11
          <br />
          64823 Groß-Umstadt
          <br />
          Deutschland
          <br />
          <br />
          info@fahrschule-grossumstadt.de
          <br />
          <a href="/imprint">Impressum</a>
        </span>
      </p>
      <h3 id="dsg-general-datatype">Arten der verarbeiteten Daten</h3>
      <p>
        - Bestandsdaten (z.B., Personen-Stammdaten, Namen oder
        Adressen).
        <br />
        - Kontaktdaten (z.B., E-Mail, Telefonnummern).
        <br />
        - Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).
        <br />
        - Nutzungsdaten (z.B., besuchte Webseiten, Interesse an
        Inhalten, Zugriffszeiten).
        <br />- Meta-/Kommunikationsdaten (z.B., Geräte-Informationen,
        IP-Adressen).
      </p>
      <h3 id="dsg-general-datasubjects">
        Kategorien betroffener Personen
      </h3>
      <p>
        Besucher und Nutzer des Onlineangebotes (Nachfolgend
        bezeichnen wir die betroffenen Personen zusammenfassend auch
        als „Nutzer“).
        <br />
      </p>
      <h3 id="dsg-general-purpose">Zweck der Verarbeitung</h3>
      <p>
        - Zurverfügungstellung des Onlineangebotes, seiner Funktionen
        und Inhalte.
        <br />
        - Beantwortung von Kontaktanfragen und Kommunikation mit
        Nutzern.
        <br />
        - Sicherheitsmaßnahmen.
        <br />
        - Reichweitenmessung/Marketing
        <br />
      </p>
      <h3 id="dsg-general-terms">Verwendete Begrifflichkeiten </h3>
      <p>
        „Personenbezogene Daten“ sind alle Informationen, die sich auf
        eine identifizierte oder identifizierbare natürliche Person
        (im Folgenden „betroffene Person“) beziehen; als
        identifizierbar wird eine natürliche Person angesehen, die
        direkt oder indirekt, insbesondere mittels Zuordnung zu einer
        Kennung wie einem Namen, zu einer Kennnummer, zu
        Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu
        einem oder mehreren besonderen Merkmalen identifiziert werden
        kann, die Ausdruck der physischen, physiologischen,
        genetischen, psychischen, wirtschaftlichen, kulturellen oder
        sozialen Identität dieser natürlichen Person sind.
        <br />
        <br />
        „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter
        Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe
        im Zusammenhang mit personenbezogenen Daten. Der Begriff
        reicht weit und umfasst praktisch jeden Umgang mit Daten.
        <br />
        <br />
        „Pseudonymisierung“ die Verarbeitung personenbezogener Daten
        in einer Weise, dass die personenbezogenen Daten ohne
        Hinzuziehung zusätzlicher Informationen nicht mehr einer
        spezifischen betroffenen Person zugeordnet werden können,
        sofern diese zusätzlichen Informationen gesondert aufbewahrt
        werden und technischen und organisatorischen Maßnahmen
        unterliegen, die gewährleisten, dass die personenbezogenen
        Daten nicht einer identifizierten oder identifizierbaren
        natürlichen Person zugewiesen werden.
        <br />
        <br />
        „Profiling“ jede Art der automatisierten Verarbeitung
        personenbezogener Daten, die darin besteht, dass diese
        personenbezogenen Daten verwendet werden, um bestimmte
        persönliche Aspekte, die sich auf eine natürliche Person
        beziehen, zu bewerten, insbesondere um Aspekte bezüglich
        Arbeitsleistung, wirtschaftliche Lage, Gesundheit, persönliche
        Vorlieben, Interessen, Zuverlässigkeit, Verhalten,
        Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu
        analysieren oder vorherzusagen.
        <br />
        <br />
        Als „Verantwortlicher“ wird die natürliche oder juristische
        Person, Behörde, Einrichtung oder andere Stelle, die allein
        oder gemeinsam mit anderen über die Zwecke und Mittel der
        Verarbeitung von personenbezogenen Daten entscheidet,
        bezeichnet.
        <br />
        <br />
        „Auftragsverarbeiter“ eine natürliche oder juristische Person,
        Behörde, Einrichtung oder andere Stelle, die personenbezogene
        Daten im Auftrag des Verantwortlichen verarbeitet.
        <br />
      </p>
      <h3 id="dsg-general-legalbasis">
        Maßgebliche Rechtsgrundlagen
      </h3>
      <p>
        Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die
        Rechtsgrundlagen unserer Datenverarbeitungen mit. Für Nutzer
        aus dem Geltungsbereich der Datenschutzgrundverordnung
        (DSGVO), d.h. der EU und des EWG gilt, sofern die
        Rechtsgrundlage in der Datenschutzerklärung nicht genannt
        wird, Folgendes: <br />
        Die Rechtsgrundlage für die Einholung von Einwilligungen ist
        Art. 6 Abs. 1 lit. a und Art. 7 DSGVO;
        <br />
        Die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer
        Leistungen und Durchführung vertraglicher Maßnahmen sowie
        Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO;
        <br />
        Die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer
        rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO;
        <br />
        Für den Fall, dass lebenswichtige Interessen der betroffenen
        Person oder einer anderen natürlichen Person eine Verarbeitung
        personenbezogener Daten erforderlich machen, dient Art. 6 Abs.
        1 lit. d DSGVO als Rechtsgrundlage.
        <br />
        Die Rechtsgrundlage für die erforderliche Verarbeitung zur
        Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt
        oder in Ausübung öffentlicher Gewalt erfolgt, die dem
        Verantwortlichen übertragen wurde ist Art. 6 Abs. 1 lit. e
        DSGVO. <br />
        Die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer
        berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. <br />
        Die Verarbeitung von Daten zu anderen Zwecken als denen, zu
        denen sie erhoben wurden, bestimmt sich nach den Vorgaben des
        Art 6 Abs. 4 DSGVO. <br />
        Die Verarbeitung von besonderen Kategorien von Daten
        (entsprechend Art. 9 Abs. 1 DSGVO) bestimmt sich nach den
        Vorgaben des Art. 9 Abs. 2 DSGVO. <br />
      </p>
      <h3 id="dsg-general-securitymeasures">Sicherheitsmaßnahmen</h3>
      <p>
        Wir treffen nach Maßgabe der gesetzlichen Vorgabenunter
        Berücksichtigung des Stands der Technik, der
        Implementierungskosten und der Art, des Umfangs, der Umstände
        und der Zwecke der Verarbeitung sowie der unterschiedlichen
        Eintrittswahrscheinlichkeit und Schwere des Risikos für die
        Rechte und Freiheiten natürlicher Personen, geeignete
        technische und organisatorische Maßnahmen, um ein dem Risiko
        angemessenes Schutzniveau zu gewährleisten.
        <br />
        <br />
        Zu den Maßnahmen gehören insbesondere die Sicherung der
        Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
        Kontrolle des physischen Zugangs zu den Daten, als auch des
        sie betreffenden Zugriffs, der Eingabe, Weitergabe, der
        Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren
        haben wir Verfahren eingerichtet, die eine Wahrnehmung von
        Betroffenenrechten, Löschung von Daten und Reaktion auf
        Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir
        den Schutz personenbezogener Daten bereits bei der
        Entwicklung, bzw. Auswahl von Hardware, Software sowie
        Verfahren, entsprechend dem Prinzip des Datenschutzes durch
        Technikgestaltung und durch datenschutzfreundliche
        Voreinstellungen.
        <br />
      </p>
      <h3 id="dsg-general-coprocessing">
        Zusammenarbeit mit Auftragsverarbeitern, gemeinsam
        Verantwortlichen und Dritten
      </h3>
      <p>
        Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber
        anderen Personen und Unternehmen (Auftragsverarbeitern,
        gemeinsam Verantwortlichen oder Dritten) offenbaren, sie an
        diese übermitteln oder ihnen sonst Zugriff auf die Daten
        gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen
        Erlaubnis (z.B. wenn eine Übermittlung der Daten an Dritte,
        wie an Zahlungsdienstleister, zur Vertragserfüllung
        erforderlich ist), Nutzer eingewilligt haben, eine rechtliche
        Verpflichtung dies vorsieht oder auf Grundlage unserer
        berechtigten Interessen (z.B. beim Einsatz von Beauftragten,
        Webhostern, etc.). <br />
        <br />
        Sofern wir Daten anderen Unternehmen unserer
        Unternehmensgruppe offenbaren, übermitteln oder ihnen sonst
        den Zugriff gewähren, erfolgt dies insbesondere zu
        administrativen Zwecken als berechtigtes Interesse und
        darüberhinausgehend auf einer den gesetzlichen Vorgaben
        entsprechenden Grundlage. <br />
      </p>
      <h3 id="dsg-general-thirdparty">
        Übermittlungen in Drittländer
      </h3>
      <p>
        Sofern wir Daten in einem Drittland (d.h. außerhalb der
        Europäischen Union (EU), des Europäischen Wirtschaftsraums
        (EWR) oder der Schweizer Eidgenossenschaft) verarbeiten oder
        dies im Rahmen der Inanspruchnahme von Diensten Dritter oder
        Offenlegung, bzw. Übermittlung von Daten an andere Personen
        oder Unternehmen geschieht, erfolgt dies nur, wenn es zur
        Erfüllung unserer (vor)vertraglichen Pflichten, auf Grundlage
        Ihrer Einwilligung, aufgrund einer rechtlichen Verpflichtung
        oder auf Grundlage unserer berechtigten Interessen geschieht.
        Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich
        erforderlicher Übermittlung, verarbeiten oder lassen wir die
        Daten nur in Drittländern mit einem anerkannten
        Datenschutzniveau, zu denen die unter dem "Privacy-Shield"
        zertifizierten US-Verarbeiter gehören oder auf Grundlage
        besonderer Garantien, wie z.B. vertraglicher Verpflichtung
        durch sogenannte Standardschutzklauseln der EU-Kommission, dem
        Vorliegen von Zertifizierungen oder verbindlichen internen
        Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO,{' '}
        <a
          href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu_de"
          target="blank"
        >
          Informationsseite der EU-Kommission
        </a>
        ).
      </p>
      <h3 id="dsg-general-rightssubject">
        Rechte der betroffenen Personen
      </h3>
      <p>
        Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
        betreffende Daten verarbeitet werden und auf Auskunft über
        diese Daten sowie auf weitere Informationen und Kopie der
        Daten entsprechend den gesetzlichen Vorgaben.
        <br />
        <br />
        Sie haben entsprechend. den gesetzlichen Vorgaben das Recht,
        die Vervollständigung der Sie betreffenden Daten oder die
        Berichtigung der Sie betreffenden unrichtigen Daten zu
        verlangen.
        <br />
        <br />
        Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht zu
        verlangen, dass betreffende Daten unverzüglich gelöscht
        werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben
        eine Einschränkung der Verarbeitung der Daten zu verlangen.
        <br />
        <br />
        Sie haben das Recht zu verlangen, dass die Sie betreffenden
        Daten, die Sie uns bereitgestellt haben nach Maßgabe der
        gesetzlichen Vorgaben zu erhalten und deren Übermittlung an
        andere Verantwortliche zu fordern. <br />
        <br />
        Sie haben ferner nach Maßgabe der gesetzlichen Vorgaben das
        Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde
        einzureichen.
        <br />
      </p>
      <h3 id="dsg-general-revokeconsent">Widerrufsrecht</h3>
      <p>
        Sie haben das Recht, erteilte Einwilligungen mit Wirkung für
        die Zukunft zu widerrufen.
      </p>
      <h3 id="dsg-general-object">Widerspruchsrecht</h3>
      <p>
        <strong>
          Sie können der künftigen Verarbeitung der Sie betreffenden
          Daten nach Maßgabe der gesetzlichen Vorgaben jederzeit
          widersprechen. Der Widerspruch kann insbesondere gegen die
          Verarbeitung für Zwecke der Direktwerbung erfolgen.
        </strong>
      </p>
      <h3 id="dsg-general-cookies">
        Cookies und Widerspruchsrecht bei Direktwerbung
      </h3>
      <p>
        Als „Cookies“ werden kleine Dateien bezeichnet, die auf
        Rechnern der Nutzer gespeichert werden. Innerhalb der Cookies
        können unterschiedliche Angaben gespeichert werden. Ein Cookie
        dient primär dazu, die Angaben zu einem Nutzer (bzw. dem Gerät
        auf dem das Cookie gespeichert ist) während oder auch nach
        seinem Besuch innerhalb eines Onlineangebotes zu speichern.
        Als temporäre Cookies, bzw. „Session-Cookies“ oder „transiente
        Cookies“, werden Cookies bezeichnet, die gelöscht werden,
        nachdem ein Nutzer ein Onlineangebot verlässt und seinen br
        /owser schließt. In einem solchen Cookie kann z.B. der Inhalt
        eines Warenkorbs in einem Onlineshop oder ein Login-Status
        gespeichert werden. Als „permanent“ oder „persistent“ werden
        Cookies bezeichnet, die auch nach dem Schließen des br /owsers
        gespeichert bleiben. So kann z.B. der Login-Status gespeichert
        werden, wenn die Nutzer diese nach mehreren Tagen aufsuchen.
        Ebenso können in einem solchen Cookie die Interessen der
        Nutzer gespeichert werden, die für Reichweitenmessung oder
        Marketingzwecke verwendet werden. Als „Third-Party-Cookie“
        werden Cookies bezeichnet, die von anderen Anbietern als dem
        Verantwortlichen, der das Onlineangebot betreibt, angeboten
        werden (andernfalls, wenn es nur dessen Cookies sind spricht
        man von „First-Party Cookies“).
        <br />
        <br />
        Wir können temporäre und permanente Cookies einsetzen und
        klären hierüber im Rahmen unserer Datenschutzerklärung auf.
        <br />
        <br />
        Sofern wir die Nutzer um eine Einwilligung in den Einsatz von
        Cookies bitten (z.B. im Rahmen einer Cookie-Einwilligung), ist
        die Rechtsgrundlage dieser Verarbeitung Art. 6 Abs. 1 lit. a.
        DSGVO. Ansonsten werden die personenbezogenen Cookies der
        Nutzer entsprechend den nachfolgenden Erläuterungen im Rahmen
        dieser Datenschutzerklärung auf Grundlage unserer berechtigten
        Interessen (d.h. Interesse an der Analyse, Optimierung und
        wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des
        Art. 6 Abs. 1 lit. f. DSGVO) oder sofern der Einsatz von
        Cookies zur Erbr /ingung unserer vertragsbezogenen Leistungen
        erforderlich ist, gem. Art. 6 Abs. 1 lit. b. DSGVO, bzw.
        sofern der Einsatz von Cookies für die Wahrnehmung einer
        Aufgabe, die im öffentlichen Interesse liegt erforderlich ist
        oder in Ausübung öffentlicher Gewalt erfolgt, gem. Art. 6 Abs.
        1 lit. e. DSGVO, verarbeitet.
        <br />
        <br />
        Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner
        gespeichert werden, werden sie gebeten die entsprechende
        Option in den Systemeinstellungen ihres br /owsers zu
        deaktivieren. Gespeicherte Cookies können in den
        Systemeinstellungen des br /owsers gelöscht werden. Der
        Ausschluss von Cookies kann zu Funktionseinschränkungen dieses
        Onlineangebotes führen.
        <br />
        <br />
        Ein genereller Widerspruch gegen den Einsatz der zu Zwecken
        des Onlinemarketing eingesetzten Cookies kann bei einer
        Vielzahl der Dienste, vor allem im Fall des Trackings, über
        die US-amerikanische Seite{' '}
        <a href="http://www.aboutads.info/choices/">
          http://www.aboutads.info/choices/
        </a>{' '}
        oder die EU-Seite{' '}
        <a href="http://www.youronlinechoices.com/">
          http://www.youronlinechoices.com/
        </a>{' '}
        erklärt werden. Des Weiteren kann die Speicherung von Cookies
        mittels deren Abschaltung in den Einstellungen des br /owsers
        erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls
        nicht alle Funktionen dieses Onlineangebotes genutzt werden
        können.
      </p>
      <h3 id="dsg-general-erasure">Löschung von Daten</h3>
      <p>
        Die von uns verarbeiteten Daten werden nach Maßgabe der
        gesetzlichen Vorgaben gelöscht oder in ihrer Verarbeitung
        eingeschränkt. Sofern nicht im Rahmen dieser
        Datenschutzerklärung ausdrücklich angegeben, werden die bei
        uns gespeicherten Daten gelöscht, sobald sie für ihre
        Zweckbestimmung nicht mehr erforderlich sind und der Löschung
        keine gesetzlichen Aufbewahrungspflichten entgegenstehen.{' '}
        <br />
        <br />
        Sofern die Daten nicht gelöscht werden, weil sie für andere
        und gesetzlich zulässige Zwecke erforderlich sind, wird deren
        Verarbeitung eingeschränkt. D.h. die Daten werden gesperrt und
        nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten,
        die aus handels- oder steuerrechtlichen Gründen aufbewahrt
        werden müssen.
      </p>
      <h3 id="dsg-general-changes">
        Änderungen und Aktualisierungen der Datenschutzerklärung
      </h3>
      <p>
        Wir bitten Sie sich regelmäßig über den Inhalt unserer
        Datenschutzerklärung zu informieren. Wir passen die
        Datenschutzerklärung an, sobald die Änderungen der von uns
        durchgeführten Datenverarbeitungen dies erforderlich machen.
        Wir informieren Sie, sobald durch die Änderungen eine
        Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine
        sonstige individuelle Benachrichtigung erforderlich wird.
      </p>
      <h3 id="dsg-services-contractualservices">
        Vertragliche Leistungen
      </h3>
      <p>
        <span className="ts-muster-content">
          Wir verarbeiten die Daten unserer Vertragspartner und
          Interessenten sowie anderer Auftraggeber, Kunden, Mandanten,
          Klienten oder Vertragspartner (einheitlich bezeichnet als
          „Vertragspartner“) entsprechend Art. 6 Abs. 1 lit. b. DSGVO,
          um ihnen gegenüber unsere vertraglichen oder
          vorvertraglichen Leistungen zu erbr /ingen. Die hierbei
          verarbeiteten Daten, die Art, der Umfang und der Zweck und
          die Erforderlichkeit ihrer Verarbeitung, bestimmen sich nach
          dem zugrundeliegenden Vertragsverhältnis. <br />
          <br />
          Zu den verarbeiteten Daten gehören die Stammdaten unserer
          Vertragspartner (z.B., Namen und Adressen), Kontaktdaten
          (z.B. E-Mailadressen und Telefonnummern) sowie Vertragsdaten
          (z.B., in Anspruch genommene Leistungen, Vertragsinhalte,
          vertragliche Kommunikation, Namen von Kontaktpersonen) und
          Zahlungsdaten (z.B., Bankverbindungen, Zahlungshistorie).{' '}
          <br />
          <br />
          Besondere Kategorien personenbezogener Daten verarbeiten wir
          grundsätzlich nicht, außer wenn diese Bestandteile einer
          beauftragten oder vertragsgemäßen Verarbeitung sind. <br />
          <br />
          Wir verarbeiten Daten, die zur Begründung und Erfüllung der
          vertraglichen Leistungen erforderlich sind und weisen auf
          die Erforderlichkeit ihrer Angabe, sofern diese für die
          Vertragspartner nicht evident ist, hin. Eine Offenlegung an
          externe Personen oder Unternehmen erfolgt nur, wenn sie im
          Rahmen eines Vertrags erforderlich ist. Bei der Verarbeitung
          der uns im Rahmen eines Auftrags überlassenen Daten, handeln
          wir entsprechend den Weisungen der Auftraggeber sowie der
          gesetzlichen Vorgaben. <br />
          <br />
          Im Rahmen der Inanspruchnahme unserer Onlinedienste, können
          wir die IP-Adresse und den Zeitpunkt der jeweiligen
          Nutzerhandlung speichern. Die Speicherung erfolgt auf
          Grundlage unserer berechtigten Interessen, als auch der
          Interessen der Nutzer am Schutz vor Missbr /auch und
          sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten
          an Dritte erfolgt grundsätzlich nicht, außer sie ist zur
          Verfolgung unserer Ansprüche gem. Art. 6 Abs. 1 lit. f.
          DSGVO erforderlich oder es besteht hierzu eine gesetzliche
          Verpflichtung gem. Art. 6 Abs. 1 lit. c. DSGVO.
          <br />
          <br />
          Die Löschung der Daten erfolgt, wenn die Daten zur Erfüllung
          vertraglicher oder gesetzlicher Fürsorgepflichten sowie für
          den Umgang mit etwaigen Gewährleistungs- und vergleichbaren
          Pflichten nicht mehr erforderlich sind, wobei die
          Erforderlichkeit der Aufbewahrung der Daten alle drei Jahre
          überprüft wird; im Übr /igen gelten die gesetzlichen
          Aufbewahrungspflichten.
          <br />
        </span>
      </p>
      <h3 id="dsg-contact">Kontaktaufnahme</h3>
      <p>
        <span className="ts-muster-content">
          Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular,
          E-Mail, Telefon oder via sozialer Medien) werden die Angaben
          des Nutzers zur Bearbeitung der Kontaktanfrage und deren
          Abwicklung gem. Art. 6 Abs. 1 lit. b. (im Rahmen
          vertraglicher-/vorvertraglicher Beziehungen), Art. 6 Abs. 1
          lit. f. (andere Anfragen) DSGVO verarbeitet.. Die Angaben
          der Nutzer können in einem Customer-Relationship-Management
          System ("CRM System") oder vergleichbarer
          Anfragenorganisation gespeichert werden.
          <br />
          <br />
          Wir löschen die Anfragen, sofern diese nicht mehr
          erforderlich sind. Wir überprüfen die Erforderlichkeit alle
          zwei Jahre; Ferner gelten die gesetzlichen
          Archivierungspflichten.
        </span>
      </p>
      <h3 id="dsg-hostingprovider">Hosting und E-Mail-Versand</h3>
      <p>
        <span className="ts-muster-content">
          Die von uns in Anspruch genommenen Hosting-Leistungen dienen
          der Zurverfügungstellung der folgenden Leistungen:
          Infrastruktur- und Plattformdienstleistungen,
          Rechenkapazität, Speicherplatz und Datenbankdienste,
          E-Mail-Versand, Sicherheitsleistungen sowie technische
          Wartungsleistungen, die wir zum Zwecke des Betriebs dieses
          Onlineangebotes einsetzen. <br />
          <br />
          Hierbei verarbeiten wir, bzw. unser Hostinganbieter
          Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten,
          Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden,
          Interessenten und Besuchern dieses Onlineangebotes auf
          Grundlage unserer berechtigten Interessen an einer
          effizienten und sicheren Zurverfügungstellung dieses
          Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art.
          28 DSGVO (Abschluss Auftragsverarbeitungsvertrag).
        </span>
      </p>
      <h3 id="dsg-logfiles">
        Erhebung von Zugriffsdaten und Logfiles
      </h3>
      <p>
        <span className="ts-muster-content">
          Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage
          unserer berechtigten Interessen im Sinne des Art. 6 Abs. 1
          lit. f. DSGVO Daten über jeden Zugriff auf den Server, auf
          dem sich dieser Dienst befindet (sogenannte Serverlogfiles).
          Zu den Zugriffsdaten gehören Name der abgerufenen Webseite,
          Datei, Datum und Uhrzeit des Abr /ufs, übertragene
          Datenmenge, Meldung über erfolgreichen Abr /uf, br /owsertyp
          nebst Version, das Betriebssystem des Nutzers, Referrer URL
          (die zuvor besuchte Seite), IP-Adresse und der anfragende
          Provider.
          <br />
          <br />
          Logfile-Informationen werden aus Sicherheitsgründen (z.B.
          zur Aufklärung von Missbr /auchs- oder Betrugshandlungen)
          für die Dauer von maximal 7 Tagen gespeichert und danach
          gelöscht. Daten, deren weitere Aufbewahrung zu Beweiszwecken
          erforderlich ist, sind bis zur endgültigen Klärung des
          jeweiligen Vorfalls von der Löschung ausgenommen.
        </span>
      </p>
      <h3 id="dsg-cdn-cloudflare">
        Content-Delivery-Network von Cloudflare
      </h3>
      <p>
        <span className="ts-muster-content">
          Wir setzen ein so genanntes "Content Delivery Network"
          (CDN), angeboten von Cloudflare, Inc., 101 Townsend St, San
          Francisco, CA 94107, USA, ein. Cloudflare ist unter dem
          Privacy-Shield-Abkommen zertifiziert und bietet hierdurch
          eine Garantie, das europäische Datenschutzrecht einzuhalten
          (
          <a
            target="_blank"
            href="https://www.privacyshield.gov/participant?id=a2zt0000000GnZKAA0&amp;status=Active"
          >
            https://www.privacyshield.gov/participant?id=a2zt0000000GnZKAA0&amp;status=Active
          </a>
          ).
          <br />
          <br />
          Ein CDN ist ein Dienst, mit dessen Hilfe Inhalte unseres
          Onlineangebotes, insbesondere große Mediendateien, wie
          Grafiken oder Skripte mit Hilfe regional verteilter und über
          das Internet verbundener Server, schneller ausgeliefert
          werden. Die Verarbeitung der Daten der Nutzer erfolgt
          alleine zu den vorgenannten Zwecken und der
          Aufrechterhaltung der Sicherheit und Funktionsfähigkeit des
          CDN.
          <br />
          <br />
          Die Nutzung erfolgt auf Grundlage unserer berechtigten
          Interessen, d.h. Interesse an einer sicheren und effizienten
          Bereitstellung, Analyse sowie Optimierung unseres
          Onlineangebotes gem. Art. 6 Abs. 1 lit. f. DSGVO. <br />
          <br />
          Weitere Informationen erhalten Sie in der
          Datenschutzerklärung von Cloudflare:&nbsp;
          <a
            target="_blank"
            href="https://www.cloudflare.com/security-policy"
          >
            https://www.cloudflare.com/security-policy
          </a>
          .
        </span>
      </p>
      <h3 id="dsg-tracking-tagmanager">Google Tag Manager</h3>
      <p>
        <span className="ts-muster-content">
          Google Tag Manager ist eine Lösung, mit der wir sog.
          Website-Tags über eine Oberfläche verwalten können (und so
          z.B. Google Analytics sowie andere Google-Marketing-Dienste
          in unser Onlineangebot einbinden). Der Tag Manager selbst
          (welches die Tags implementiert) verarbeitet keine
          personenbezogenen Daten der Nutzer. Im Hinblick auf die
          Verarbeitung der personenbezogenen Daten der Nutzer wird auf
          die folgenden Angaben zu den Google-Diensten verwiesen.
          Nutzungsrichtlinien:{' '}
          <a
            target="_blank"
            href="https://www.google.com/intl/de/tagmanager/use-policy.html"
          >
            https://www.google.com/intl/de/tagmanager/use-policy.html
          </a>
          .<br />
        </span>
      </p>
      <h3 id="dsg-ga-googleanalytics">Google Analytics</h3>
      <p>
        <span className="ts-muster-content">
          Wir setzen Google Analytics, einen Webanalysedienst der
          Google Ireland Limited, Gordon House, Barrow Street, Dublin
          4, Irland („Google“) ein. Google verwendet Cookies. Die
          durch das Cookie erzeugten Informationen über Benutzung des
          Onlineangebotes durch die Nutzer werden in der Regel an
          einen Server von Google in den USA übertragen und dort
          gespeichert.
          <br />
          <br />
          Google wird diese Informationen in unserem Auftrag benutzen,
          um die Nutzung unseres Onlineangebotes durch die Nutzer
          auszuwerten, um Reports über die Aktivitäten innerhalb
          dieses Onlineangebotes zusammenzustellen und um weitere, mit
          der Nutzung dieses Onlineangebotes und der Internetnutzung
          verbundene Dienstleistungen, uns gegenüber zu erbr /ingen.
          Dabei können aus den verarbeiteten Daten pseudonyme
          Nutzungsprofile der Nutzer erstellt werden.
          <br />
          <br />
          Wir setzen Google Analytics nur mit aktivierter
          IP-Anonymisierung ein. Das bedeutet, die IP-Adresse der
          Nutzer wird von Google innerhalb von Mitgliedstaaten der
          Europäischen Union oder in anderen Vertragsstaaten des
          Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur
          in Ausnahmefällen wird die volle IP-Adresse an einen Server
          von Google in den USA übertragen und dort gekürzt.
          <br />
          <br />
          Die von dem br /owser des Nutzers übermittelte IP-Adresse
          wird nicht mit anderen Daten von Google zusammengeführt. Die
          Nutzer können die Speicherung der Cookies durch eine
          entsprechende Einstellung ihrer br /owser-Software
          verhindern; die Nutzer können darüber hinaus die Erfassung
          der durch das Cookie erzeugten und auf ihre Nutzung des
          Onlineangebotes bezogenen Daten an Google sowie die
          Verarbeitung dieser Daten durch Google verhindern, indem sie
          das unter folgendem Link verfügbare br /owser-Plugin
          herunterladen und installieren:&nbsp;
          <a
            target="_blank"
            href="http://tools.google.com/dlpage/gaoptout?hl=de"
          >
            http://tools.google.com/dlpage/gaoptout?hl=de
          </a>
          .<br />
          <br />
          Sofern wir die Nutzer um eine Einwilligung bitten (z.B. im
          Rahmen einer Cookie-Einwilligung), ist die Rechtsgrundlage
          dieser Verarbeitung Art. 6 Abs. 1 lit. a. DSGVO. Ansonsten
          werden die personenbezogenen Daten der Nutzer auf Grundlage
          unserer berechtigten Interessen (d.h. Interesse an der
          Analyse, Optimierung und wirtschaftlichem Betrieb unseres
          Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO)
          verarbeitet.
          <br />
          <br />
          Soweit Daten in den USA verarbeitet werden, weisen wir
          daraufhin, dass Google unter dem Privacy-Shield-Abkommen
          zertifiziert ist und hierdurch zusichert, das europäische
          Datenschutzrecht einzuhalten (
          <a
            target="_blank"
            href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
          >
            https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
          </a>
          ).
          <br />
          <br />
          Weitere Informationen zur Datennutzung durch Google,
          Einstellungs- und Widerspruchsmöglichkeiten, erfahren Sie in
          der Datenschutzerklärung von Google (
          <a
            target="_blank"
            href="https://policies.google.com/privacy"
          >
            https://policies.google.com/privacy
          </a>
          ) sowie in den Einstellungen für die Darstellung von
          Werbeeinblendungen durch Google{' '}
          <a
            target="_blank"
            href="https://adssettings.google.com/authenticated"
          >
            (https://adssettings.google.com/authenticated
          </a>
          ).
          <br />
          <br />
          Die personenbezogenen Daten der Nutzer werden nach 14
          Monaten gelöscht oder anonymisiert.
        </span>
      </p>
      <h3 id="dsg-ga-universal">Google Universal Analytics</h3>
      <p>
        <span className="ts-muster-content">
          Wir setzen Google Analytics in der Ausgestaltung als „
          <a
            target="_blank"
            href="https://support.google.com/analytics/answer/2790010?hl=de&amp;ref_topic=6010376"
          >
            Universal-Analytics
          </a>
          “ ein. „Universal Analytics“ bezeichnet ein Verfahren von
          Google Analytics, bei dem die Nutzeranalyse auf Grundlage
          einer pseudonymen Nutzer-ID erfolgt und damit ein
          pseudonymes Profil des Nutzers mit Informationen aus der
          Nutzung verschiedener Geräten erstellt wird (sog.
          „Cross-Device-Tracking“).
        </span>
      </p>
      <h3 id="dsg-socialmedia">Onlinepräsenzen in sozialen Medien</h3>
      <p>
        <span className="ts-muster-content">
          Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke
          und Plattformen, um mit den dort aktiven Kunden,
          Interessenten und Nutzern kommunizieren und sie dort über
          unsere Leistungen informieren zu können.
          <br />
          <br />
          Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb
          des Raumes der Europäischen Union verarbeitet werden können.
          Hierdurch können sich für die Nutzer Risiken ergeben, weil
          so z.B. die Durchsetzung der Rechte der Nutzer erschwert
          werden könnte. Im Hinblick auf US-Anbieter die unter dem
          Privacy-Shield zertifiziert sind, weisen wir darauf hin,
          dass sie sich damit verpflichten, die Datenschutzstandards
          der EU einzuhalten.
          <br />
          <br />
          Ferner werden die Daten der Nutzer im Regelfall für
          Marktforschungs- und Werbezwecke verarbeitet. So können z.B.
          aus dem Nutzungsverhalten und sich daraus ergebenden
          Interessen der Nutzer Nutzungsprofile erstellt werden. Die
          Nutzungsprofile können wiederum verwendet werden, um z.B.
          Werbeanzeigen innerhalb und außerhalb der Plattformen zu
          schalten, die mutmaßlich den Interessen der Nutzer
          entsprechen. Zu diesen Zwecken werden im Regelfall Cookies
          auf den Rechnern der Nutzer gespeichert, in denen das
          Nutzungsverhalten und die Interessen der Nutzer gespeichert
          werden. Ferner können in den Nutzungsprofilen auch Daten
          unabhängig der von den Nutzern verwendeten Geräte
          gespeichert werden (insbesondere wenn die Nutzer Mitglieder
          der jeweiligen Plattformen sind und bei diesen eingeloggt
          sind).
          <br />
          <br />
          Die Verarbeitung der personenbezogenen Daten der Nutzer
          erfolgt auf Grundlage unserer berechtigten Interessen an
          einer effektiven Information der Nutzer und Kommunikation
          mit den Nutzern gem. Art. 6 Abs. 1 lit. f. DSGVO. Falls die
          Nutzer von den jeweiligen Anbietern der Plattformen um eine
          Einwilligung in die vorbeschriebene Datenverarbeitung
          gebeten werden, ist die Rechtsgrundlage der Verarbeitung
          Art. 6 Abs. 1 lit. a., Art. 7 DSGVO.
          <br />
          <br />
          Für eine detaillierte Darstellung der jeweiligen
          Verarbeitungen und der Widerspruchsmöglichkeiten (Opt-Out),
          verweisen wir auf die nachfolgend verlinkten Angaben der
          Anbieter.
          <br />
          <br />
          Auch im Fall von Auskunftsanfragen und der Geltendmachung
          von Nutzerrechten, weisen wir darauf hin, dass diese am
          effektivsten bei den Anbietern geltend gemacht werden
          können. Nur die Anbieter haben jeweils Zugriff auf die Daten
          der Nutzer und können direkt entsprechende Maßnahmen
          ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe
          benötigen, dann können Sie sich an uns wenden.
          <br />
          <br />- Facebook, -Seiten, -Gruppen, (Facebook Ireland Ltd.,
          4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland)
          auf Grundlage einer{' '}
          <a
            target="_blank"
            href="https://www.facebook.com/legal/terms/page_controller_addendum"
          >
            Vereinbarung über gemeinsame Verarbeitung
            personenbezogener Daten
          </a>{' '}
          - Datenschutzerklärung:{' '}
          <a
            target="_blank"
            href="https://www.facebook.com/about/privacy/"
          >
            https://www.facebook.com/about/privacy/
          </a>
          , speziell für Seiten:{' '}
          <a
            target="_blank"
            href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
          >
            https://www.facebook.com/legal/terms/information_about_page_insights_data
          </a>{' '}
          , Opt-Out:{' '}
          <a
            target="_blank"
            href="https://www.facebook.com/settings?tab=ads"
          >
            https://www.facebook.com/settings?tab=ads
          </a>{' '}
          und{' '}
          <a target="_blank" href="http://www.youronlinechoices.com">
            http://www.youronlinechoices.com
          </a>
          , Privacy Shield:{' '}
          <a
            target="_blank"
            href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active"
          >
            https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active
          </a>
          .<br />
          <br />- Google/ YouTube (Google Ireland Limited, Gordon
          House, Barrow Street, Dublin 4, Irland) –
          Datenschutzerklärung: &nbsp;
          <a
            target="_blank"
            href="https://policies.google.com/privacy"
          >
            https://policies.google.com/privacy
          </a>
          , Opt-Out:{' '}
          <a
            target="_blank"
            href="https://adssettings.google.com/authenticated"
          >
            https://adssettings.google.com/authenticated
          </a>
          , Privacy Shield:{' '}
          <a
            target="_blank"
            href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
          >
            https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
          </a>
          .<br />
          <br />- Instagram (Instagram Inc., 1601 Willow Road, Menlo
          Park, CA, 94025, USA) – Datenschutzerklärung/ Opt-Out:{' '}
          <a
            target="_blank"
            href="http://instagram.com/about/legal/privacy/"
          >
            http://instagram.com/about/legal/privacy/
          </a>
          .<br />
          <br />- Twitter (Twitter Inc., 1355 Market Street, Suite
          900, San Francisco, CA 94103, USA) - Datenschutzerklärung:{' '}
          <a target="_blank" href="https://twitter.com/de/privacy">
            https://twitter.com/de/privacy
          </a>
          , Opt-Out:{' '}
          <a
            target="_blank"
            href="https://twitter.com/personalization"
          >
            https://twitter.com/personalization
          </a>
          , Privacy Shield:{' '}
          <a
            target="_blank"
            href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active"
          >
            https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active
          </a>
          .<br />
          <br />- Pinterest (Pinterest Inc., 635 High Street, Palo
          Alto, CA, 94301, USA) – Datenschutzerklärung/ Opt-Out:{' '}
          <a
            target="_blank"
            href="https://about.pinterest.com/de/privacy-policy"
          >
            https://about.pinterest.com/de/privacy-policy
          </a>
          .<br />
          <br />- LinkedIn (LinkedIn Ireland Unlimited Company Wilton
          Place, Dublin 2, Irland) - Datenschutzerklärung{' '}
          <a
            target="_blank"
            href="https://www.linkedin.com/legal/privacy-policy"
          >
            https://www.linkedin.com/legal/privacy-policy
          </a>{' '}
          , Opt-Out:{' '}
          <a
            target="_blank"
            href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
          >
            https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
          </a>
          , Privacy Shield:&nbsp;
          <a
            target="_blank"
            href="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active"
          >
            https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active
          </a>
          .<br />
          <br />- Xing (XING AG, Dammtorstraße 29-32, 20354 Hamburg,
          Deutschland) - Datenschutzerklärung/ Opt-Out:{' '}
          <a
            target="_blank"
            href="https://privacy.xing.com/de/datenschutzerklaerung"
          >
            https://privacy.xing.com/de/datenschutzerklaerung
          </a>
          .<br />
          <br />- Wakalet (Wakelet Limited, 76 Quay Street,
          Manchester, M3 4PR, United Kingdom) - Datenschutzerklärung/
          Opt-Out:{' '}
          <a target="_blank" href="https://wakelet.com/privacy.html">
            https://wakelet.com/privacy.html
          </a>
          .<br />
          <br />- Soundcloud (SoundCloud Limited, Rheinsberger Str.
          76/77, 10115 Berlin, Deutschland) - Datenschutzerklärung/
          Opt-Out:{' '}
          <a
            target="_blank"
            href="https://soundcloud.com/pages/privacy"
          >
            https://soundcloud.com/pages/privacy
          </a>
          .
        </span>
      </p>
      <h3 id="dsg-thirdparty-einleitung">
        Einbindung von Diensten und Inhalten Dritter
      </h3>
      <p>
        <span className="ts-muster-content">
          Wir setzen innerhalb unseres Onlineangebotes auf Grundlage
          unserer berechtigten Interessen (d.h. Interesse an der
          Analyse, Optimierung und wirtschaftlichem Betrieb unseres
          Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO)
          Inhalts- oder Serviceangebote von Drittanbietern ein, um
          deren Inhalte und Services, wie z.B. Videos oder
          Schriftarten einzubinden (nachfolgend einheitlich bezeichnet
          als “Inhalte”). <br />
          <br />
          Dies setzt immer voraus, dass die Drittanbieter dieser
          Inhalte, die IP-Adresse der Nutzer wahrnehmen, da sie ohne
          die IP-Adresse die Inhalte nicht an deren br /owser senden
          könnten. Die IP-Adresse ist damit für die Darstellung dieser
          Inhalte erforderlich. Wir bemühen uns nur solche Inhalte zu
          verwenden, deren jeweilige Anbieter die IP-Adresse lediglich
          zur Auslieferung der Inhalte verwenden. Drittanbieter können
          ferner so genannte Pixel-Tags (unsichtbare Grafiken, auch
          als "Web Beacons" bezeichnet) für statistische oder
          Marketingzwecke verwenden. Durch die "Pixel-Tags" können
          Informationen, wie der Besucherverkehr auf den Seiten dieser
          Website ausgewertet werden. Die pseudonymen Informationen
          können ferner in Cookies auf dem Gerät der Nutzer
          gespeichert werden und unter anderem technische
          Informationen zum br /owser und Betriebssystem, verweisende
          Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung
          unseres Onlineangebotes enthalten, als auch mit solchen
          Informationen aus anderen Quellen verbunden werden.
        </span>
      </p>
      <h3 id="dsg-thirdparty-googlefonts">Google Fonts</h3>
      <p>
        <span className="ts-muster-content">
          Wir binden die Schriftarten ("Google Fonts") des Anbieters
          Google Ireland Limited, Gordon House, Barrow Street, Dublin
          4, Irland, ein. Nach Angaben von Google werden die Daten der
          Nutzer allein zu Zwecken der Darstellung der Schriftarten im
          br /owser der Nutzer verwendet. Die Einbindung erfolgt auf
          Grundlage unserer berechtigten Interessen an einer technisch
          sicheren, wartungsfreien und effizienten Nutzung von
          Schriftarten, deren einheitlicher Darstellung sowie
          Berücksichtigung möglicher lizenzrechtlicher Restriktionen
          für deren Einbindung. Datenschutzerklärung:{' '}
          <a
            target="_blank"
            href="https://www.google.com/policies/privacy/"
          >
            https://www.google.com/policies/privacy/
          </a>
          .
        </span>
      </p>
      <h3 id="dsg-thirdparty-googlemaps">Google Maps</h3>
      <p>
        <span className="ts-muster-content">
          Wir binden die Landkarten des Dienstes “Google Maps” des
          Anbieters Google Ireland Limited, Gordon House, Barrow
          Street, Dublin 4, Irland, ein. Zu den verarbeiteten Daten
          können insbesondere IP-Adressen und Standortdaten der Nutzer
          gehören, die jedoch nicht ohne deren Einwilligung (im
          Regelfall im Rahmen der Einstellungen ihrer Mobilgeräte
          vollzogen), erhoben werden. Die Daten können in den USA
          verarbeitet werden. Datenschutzerklärung:{' '}
          <a
            target="_blank"
            href="https://www.google.com/policies/privacy/"
          >
            https://www.google.com/policies/privacy/
          </a>
          , Opt-Out:{' '}
          <a
            target="_blank"
            href="https://adssettings.google.com/authenticated"
          >
            https://adssettings.google.com/authenticated
          </a>
          .
        </span>
      </p>
      <a
        href="https://datenschutz-generator.de"
        className="dsg1-6"
        rel="nofollow"
        target="_blank"
      >
        Erstellt mit Datenschutz-Generator.de von RA Dr. Thomas
        Schwenke
      </a>
    </div>
  </div>
);
